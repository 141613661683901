<template>
  <div class="container">
    <div class="header flex-between-start">
      <div class="handle">
        <div class="title">白名单管理</div>
        <el-button type="primary" @click="handleEdit()">新增白名单规则</el-button>
      </div>
      <div class="search">
        <el-select v-model="status" placeholder="请选择状态">
          <el-option
            v-for="item in enums.commonStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button @click="pageEmit(1)">搜索</el-button>
      </div>
    </div>

    <el-table :data="tableData.items" v-loading="loading">
      <el-table-column prop="name" label="平台名称"></el-table-column>
      <el-table-column prop="salt" label="加密盐"></el-table-column>
      <el-table-column prop="count" label="白名单总条数"></el-table-column>
      <el-table-column prop="status" label="状态">
        <template #default="{ row }">
          <el-tag size="small" :type="getStatusType(row)">{{getStatus(row)}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="300">
        <template #default="scope">
          <el-button plain size="small" type="warning" @click="handleImport(scope.row)">导入</el-button>
          <el-button plain size="small" type="warning" @click="handleCheck(scope.row)">校验</el-button>
          <el-button plain size="small" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button plain size="small" type="danger" @click="handleDelete(scope.row.id, delTips)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <page
      @page-event="pageEmit"
      :current-page="pageParam.currentPage"
      :total="tableData.total"
      :page-size="tableData.count"
    ></page>

    <!-- 导入白名单 -->
    <import-list ref="importList" @confirm="pageEmit(1)"></import-list>
    <!-- 校验 -->
    <check-num ref="checkNum" @confirm="pageEmit(1)"></check-num>
    <!-- 新增/编辑对话框 -->
    <edit-rule ref="editRule" @confirm="pageEmit(1)"></edit-rule>
  </div>
</template>

<script>
import { ref, onMounted, reactive, getCurrentInstance } from 'vue'
import whiteModel from '@/model/white-list'
import commonHandle from '@/hooks/useCommon'
import enums from '@/lin/util/enum'
import importList from './components/import-list.vue'
import checkNum from './components/check-num.vue'
import editRule from './components/edit-rule.vue'

export default {
  components: {
    importList,
    checkNum,
    editRule,
  },
  setup(props) {
    const status = ref(1)
    const ctx = getCurrentInstance()
    const delTips = ref('删除该白名单规则后，所属活动的白名单配置将一起清空，是否继续？')

    // 获取平台列表
    const getData = async pageQuery => {
      loading.value = true
      const res = await whiteModel.getWhiteListRule({
        page: pageQuery?.page || pageParam.currentPage - 1,
        count: pageQuery?.count || pageParam.count,
        status: status.value,
      })
      loading.value = false
      tableData.value = res
    }

    // 导入
    const handleImport = (item = {}) => {
      ctx.refs.importList.init(item)
    }

    // 校验
    const handleCheck = (item = {}) => {
      ctx.refs.checkNum.init(item)
    }

    // 新增/编辑
    const handleEdit = (item = {}) => {
      ctx.refs.editRule.init(item)
    }

    // 删除
    const deleteFun = async id => {
      let res
      res = await whiteModel.delWhiteListRule(id)
      return res
    }

    const getStatusType = (row) => {
      if (row.id) return enums.commonStatus.filter(i => i.value == row.status)[0].type
    }

    const getStatus = (row) => {
      if (row.id) return  enums.commonStatus.filter(i => i.value == row.status)[0].label
    }

    const { tableData, loading, pageParam, handleDelete, pageEmit } = commonHandle(getData, deleteFun)

    onMounted(() => {
      getData()
    })

    return {
      loading,
      pageParam,
      status,
      delTips,
      tableData,
      handleImport,
      handleCheck,
      handleEdit,
      handleDelete,
      getStatusType,
      getStatus,
      enums,
      pageEmit,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/realize/custom';
</style>
